import React, { useState } from "react";
import logoCar from "../../assets/img/ev-logo-neww.png"
import { useAuth } from "../../hooks/useAuth";
import { Formik } from 'formik';
import { Link, useNavigate } from "react-router-dom";
import {
  EyeIcon,
  EyeSlashIcon
} from "@heroicons/react/24/outline";

const Login = () => {
  const { login } = useAuth();
  const [responseErrors, setResponseErrors] = useState(false);
  const navigate = useNavigate();
  const [showPass, setShowPass] = useState(false);

  const handleSubmit = async (values, {setSubmitting}) => {
    const url = "https://evmainbackend.theevshowroom.com/api/login";
    try{
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          email: values.email,
          password: values.password,
          roleId: 1
        })
      });
      const dataResponse = await response.json();
      if(dataResponse.status){
        const user =  dataResponse.data;
        login(user);
        setResponseErrors(null);
        // Temporary measure
        navigate('/admin/products/1/audi');
      }else{
        setResponseErrors(dataResponse.message);
      }
      setSubmitting(false);
    } catch(e){
      console.log('Error while login: ',e);
    }
  }

  const validateForm = (values) => {
    setResponseErrors(null);
    const errors = {};
    if (!values.email) {
      errors.email = 'Email is required';
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
    ) {
      errors.email = 'Invalid email address';
    }

    if(!values.password){
      errors.password = 'Password is required'
    } else if( values.password.length < 8){
      errors.password = 'Password should be of minimum 8 characters length'
    }
    return errors;
  }

  return (
    <section className="flex justify-center">
      <div className="h-screen">
        <div className="items-center justify-center bg-white">
          <div className="w-full">
            <div className="block rounded-lg">
              <div className="lg:flex lg:flex-wrap">

                <div className="lg:flex items-center  bg-[#eee] h-screen lg:w-6/12 hidden">
                  <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                    <h4 className="mb-6 text-2xl font-bold text-black">
                      Delivering 100'000s of NEW qualified leads to Electric Car Manufactures every month
                    </h4>
                    <p className="text-xl text-gray-700">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                      ea commodo consequat.
                    </p>
                  </div>
                </div>

                <div className="px-4 md:px-0 lg:w-6/12 ">
                  <div className="md:mx-6 md:p-12 ">
                    <div className="text-center mb-8">
                      <h4 className=" mt-24 pb-1 sm:text-2xl text-xl font-semibold">
                        Welcome Back!
                      </h4>
                      <p className="mb-4">Please login to your Admin account</p>
                    </div>

                    <Formik
                      initialValues={{ email: '', password: '' }}
                      validate={validateForm}
                      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
                    >
                      {({ 
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting, 
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                        >
                          <div className="">
                            <div className="mb-10">
                              <input
                                type="email"
                                name="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                autoComplete="email"
                                placeholder="Username"
                                className={`block w-full rounded outline-none px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]`}
                              />
                              <p className="text-orange-600 ml-2">{errors.email && touched.email && errors.email}</p>
                            </div>
                            <div className="mb-10">
                              <div className="flex items-center relative">
                                <input
                                  type={showPass ? 'text' : 'password'}
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  placeholder="Password"
                                  className="block w-full rounded outline-none px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]"
                                />
                                <button type="button" className="absolute right-0 ring-0" onClick={() => setShowPass(prev => !prev)}>
                                  {showPass ? <EyeSlashIcon className="ring-0 w-6 h-6 mx-2"/> :<EyeIcon className="ring-0 w-6 h-6 mx-2" />}
                                </button>
                              </div>
                              <p className="text-orange-600 ml-2">{errors.password && touched.password && errors.password}</p>
                            </div>
                          </div>
                          <p className="text-orange-600 text-center mt-2">{responseErrors}</p>

                          <div className="mb-12 pb-1 pt-1 text-center">
                            <button
                              type="submit"
                              disabled={isSubmitting}
                              className="block w-full rounded-md bg-black px-3.5 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-black-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Login
                            </button>

                            {/* <div className="flex flex-row justify-between mb-12 pb-1 pt-1 text-center">
                              <div>
                                <p className="mb-0 mr-2 mt-10">Don't have an account yet?</p>
                                <Link to="/register">
                                  <span className="text-black text-sm underline">
                                    Register with us
                                  </span>
                                </Link>
                              </div>
                              <div>
                                <p className="mb-0 mr-2 mt-10">Forgot Password?</p>
                                <Link to="/forgot-password">
                                  <span className="text-black text-sm underline">
                                    Reset Password
                                  </span>
                                </Link>
                              </div>
                            </div> */}
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <img
          src={logoCar}
          alt="Logo"
          className="absolute top-0 left-6 mt-4 mr-4 w-24 h-16"
        />
      </div>
    </section>
  );
};

export default Login;
