import React from 'react'
import { Table2 } from '../../../components/dashboard/Table'

const TestDrive = () => {
  return (
    <div className='sm:px-8 w-full min-h-[80vh] flex justify-center items-center'>
      {/* <Table2/> */}
      <div>
        <p className='text-5xl'>Work In progress</p>
      </div>
    </div>
  )
}

export default TestDrive