import { useEffect, useState } from 'react';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useToast } from '../../../components/toast/use-toast.tsx';

const BlacklistIPsPage = () => {
  const [blacklistedIPs, setBlacklistedIPs] = useState([]);
  const { toast } = useToast();

  // Fetch blacklisted IPs when the component is mounted
  const fetchBlacklistedIPs = async () => {
    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/getBlacklistedIPs', {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setBlacklistedIPs(responseData.data);
      } else {
        setBlacklistedIPs([]);
      }
    } catch (e) {
      console.log('Error fetching blacklisted IPs:', e);
    }
  };

  useEffect(() => {
    fetchBlacklistedIPs();
  }, []);

  // Form submission for adding a new blacklisted IP
  const handleAddBlacklistIP = async (values, { resetForm }) => {
    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/addBlacklistIP', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(values),
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        toast({
          title: "IP added successfully!",
        });
        resetForm();  // Reset the form fields
        fetchBlacklistedIPs(); // Fetch updated list of blacklisted IPs
      } else {
        toast({
          title: "Error adding IP",
        });
      }
    } catch (e) {
      console.log('Error adding blacklisted IP:', e);
    }
  };

  // Handle delete action
  const handleDeleteIP = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this IP from the blacklist?');
    if (!confirmDelete) return;

    try {
      const response = await fetch(`https://evmainbackend.theevshowroom.com/api/deleteBlacklistedIP/${id}`, {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        toast({
          title: "IP removed from blacklist!",
        });
        fetchBlacklistedIPs();  // Refresh the list after deletion
      } else {
        toast({
          title: "Error removing IP",
        });
      }
    } catch (e) {
      console.log('Error removing blacklisted IP:', e);
    }
  };

  return (
    <div className="container mx-auto px-4 flex flex-col">
      <h1 className="text-2xl font-bold mb-6">Blacklist IPs</h1>
      
      {/* Form to add new blacklisted IP */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-4">Add New Blacklisted IP</h2>
        <Formik
          initialValues={{ ip_address: '', added_by: '1' }}  // Assuming '1' is the admin ID
          validate={values => {
            const errors = {};
            if (!values.ip_address) {
              errors.ip_address = 'IP address is required';
            }
            return errors;
          }}
          onSubmit={handleAddBlacklistIP}
        >
          {({ isSubmitting }) => (
            <Form className="space-y-4">
              <div>
                <label htmlFor="ip_address" className="block text-sm font-medium text-gray-700">IP Address</label>
                <Field
                  type="text"
                  name="ip_address"
                  className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                />
                <ErrorMessage name="ip_address" component="div" className="text-red-500 text-sm mt-1" />
              </div>
              
              <div>
                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="flex justify-center py-2 px-8 mx-auto border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  Add IP
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </div>

      {/* Table to display blacklisted IPs */}
      <div className="overflow-auto flex-grow">
        <h2 className="text-xl font-semibold mb-4">Blacklisted IPs</h2>
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">ID</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">IP Address</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Created Date</th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">Actions</th>
            </tr>
          </thead>
          <tbody>
            {blacklistedIPs.length > 0 ? blacklistedIPs.map(ip => (
              <tr key={ip.id}>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{ip.id}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{ip.ip_address}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{new Date(ip.created).toLocaleDateString()}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">
                  <button
                    className="text-red-600 hover:text-red-800"
                    onClick={() => handleDeleteIP(ip.id)}
                  >
                    Delete
                  </button>
                </td>
              </tr>
            )) : (
              <tr>
                <td className="px-6 py-4 border-b border-gray-200 text-sm" colSpan="4">No blacklisted IPs found.</td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default BlacklistIPsPage;
