import React, { useContext, useState } from 'react'
import { Formik } from 'formik'
import { useParams } from 'react-router-dom'
import { useToast } from '../../../components/toast/use-toast.tsx'
import { AuthContext } from '../../../context/AuthContext.js'

const EditProfile = () => {
  const { companyId, companyName } = useParams()
  const { toast } = useToast()
  const [isUploading, setIsUploading] = useState(false)
  const { user } = useContext(AuthContext);

  const handleSubmit = async (values, { setSubmitting }) => {
    const formData = new FormData()
    formData.append('companyLogo', values.companyLogo)
    formData.append('companyThumbnail', values.companyThumbnail)
    formData.append('companyId', companyId)

    setIsUploading(true);
    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/editVendorProfile', {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          token: user?.token
        },
      })
      const data = await response.json()

      if (response.ok) {
        toast({
          title: 'Profile Updated',
          description: 'Company profile updated successfully',
        })
      } else {
        throw new Error(data.message || 'Profile update failed')
      }
      setIsUploading(false);
    } catch (error) {
      console.error('Error updating profile:', error)
      setIsUploading(false);
      toast({
        title: 'Error',
        description: 'Profile update failed',
        status: 'error',
      })
    }
    setSubmitting(false)
  }

  return (
    <div className='p-4 max-w-2xl mx-auto'>
      <h2 className='text-3xl font-bold mb-6 text-center'>Edit Profile</h2>
      <Formik
        initialValues={{
          companyLogo: null,
          companyThumbnail: null,
        }}
        validate={values => {
          const errors = {}
          return errors
        }}
        onSubmit={handleSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          setFieldValue,
          isSubmitting,
        }) => (
          <form onSubmit={handleSubmit} className='space-y-6'>
            <div>
              <label className='block text-sm font-medium text-gray-700'>Company Logo</label>
              <div className='mt-2 relative flex items-center'>
                <input
                  type='file'
                  name='companyLogo'
                  onChange={event => {
                    setFieldValue('companyLogo', event.currentTarget.files[0])
                  }}
                  onBlur={handleBlur}
                  disabled={isUploading}
                  className='block w-full text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 focus:outline-none'
                />
                {isUploading && (
                  <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 rounded-lg">
                    <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                    </svg>
                  </div>
                )}
              </div>
              {errors.companyLogo && touched.companyLogo && (
                <div className='text-red-600 text-sm mt-1'>{errors.companyLogo}</div>
              )}
            </div>
            <div>
              <label className='block text-sm font-medium text-gray-700'>Company Thumbnail</label>
              <div className='mt-2 relative flex items-center'>
                <input
                  type='file'
                  name='companyThumbnail'
                  onChange={event => {
                    setFieldValue('companyThumbnail', event.currentTarget.files[0])
                  }}
                  onBlur={handleBlur}
                  disabled={isUploading}
                  className='block w-full text-sm text-gray-900 border border-gray-300 rounded-sm cursor-pointer bg-gray-50 focus:outline-none'
                />
                {isUploading && (
                  <div className="absolute inset-0 flex justify-center items-center bg-white bg-opacity-75 rounded-lg">
                    <svg className="animate-spin h-5 w-5 text-gray-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                      <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                      <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8v8z"></path>
                    </svg>
                  </div>
                )}
              </div>
              {errors.companyThumbnail && touched.companyThumbnail && (
                <div className='text-red-600 text-sm mt-1'>{errors.companyThumbnail}</div>
              )}
            </div>
            <div className='flex justify-center'>
              <button
                type='submit'
                disabled={isSubmitting || isUploading}
                className='px-6 py-3 bg-blue-600 text-white rounded-lg text-lg font-medium hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2'
              >
                Submit
              </button>
            </div>
          </form>
        )}
      </Formik>
    </div>
  )
}

export default EditProfile
