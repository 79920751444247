"use client"

import * as React from "react"
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../../../lib/utils"
import { Button } from "../../../../components/button/index.tsx"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../../components/command/index.tsx"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/popover/index.tsx"


export function ModelSelectors({
  modelsList,
  subModelsList,
  handleModelChange,
  handleSubmodelChange,
  selectedModel,
  selectedSubModel,
  disabled,
  className,
  widthInPX,
}) {
  const [modelOpen, setModelOpen] = React.useState(false)
  const [subModelOpen, setSubModelOpen] = React.useState(false)

  return (
    <div className={cn("px-8 flex gap-4", className)}>
      <Popover open={modelOpen} onOpenChange={setModelOpen}>
        <PopoverTrigger disabled={disabled} asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={modelOpen}
            className={cn("w-[200px] justify-between capitalize", widthInPX)}
          >
            {selectedModel
              ? modelsList.find((model) => model.id.toString() === selectedModel.id.toString())?.model
              : "Select Model."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn("w-[200px] p-0", widthInPX)}>
          <Command>
            <CommandInput placeholder="Search Model" type='password' name='devicePassword' autoComplete='new-password' />
            <CommandEmpty>No Model Found.</CommandEmpty>
            <CommandGroup>
              {modelsList.map((model) => (
                <CommandItem
                  key={model.id}
                  value={model.id}
                  onSelect={() => {
                    handleModelChange(model.id)
                    setModelOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedModel?.id.toString() === model.id.toString() ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {model.model}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
      {subModelsList && subModelsList.length ? <Popover open={subModelOpen} onOpenChange={setSubModelOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={subModelOpen}
            className="min-w-[200px] justify-between capitalize"
          >
            {selectedSubModel
              ? subModelsList.find((model) => model.id.toString() === selectedSubModel.id.toString())?.model
              : "Select Submodel"}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="min-w-[200px] p-0">
          <Command>
            <CommandInput placeholder="Search Sub-Model" type='password' name='devicePassword' autoComplete='new-password' />
            <CommandEmpty>No Sub-Model found.</CommandEmpty>
            <CommandGroup>
              <CommandItem
                onSelect={() => {
                  handleSubmodelChange('none')
                  setSubModelOpen(false)
                }}
              >
                <Check
                  className="mr-2 h-4 w-4 opacity-0"
                />
                Select SubModel
              </CommandItem>
              {subModelsList.map((model) => (
                <CommandItem
                  key={model.id}
                  value={model.id}
                  onSelect={() => {
                    handleSubmodelChange(model.id)
                    setSubModelOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedSubModel?.id.toString() === model.id.toString() ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {model.model}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover> : null}
    </div>
  )
}
