import React from 'react'
import { Table4 } from '../../../components/dashboard/Table'

const NewsLetter = () => {
  return (
    <div className='sm:px-8'>
      <Table4 />
    </div>
  )
}

export default NewsLetter
