import React, { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { useToast } from "../../../components/toast/use-toast.tsx"

const AddTransactionForm = () => {
  const initialValues = {
    transactionId: '',
    vendorId: '',
    amount: '',
    paymentType: '',
    transactionDate: '',
    createdBy: 'JAMES',
    otherCreatedBy: ''
  };
  const { toast } = useToast();

  const [isOtherSelected, setIsOtherSelected] = useState(false);

  const validate = values => {
    const errors = {};
    if (!values.transactionId) {
      errors.transactionId = 'Transaction ID is required';
    }
    if (!values.vendorId) {
      errors.vendorId = 'Vendor ID is required';
    }
    if (!values.amount) {
      errors.amount = 'Amount is required';
    } else if (values.amount <= 0) {
      errors.amount = 'Amount must be positive';
    }
    if (!values.paymentType) {
      errors.paymentType = 'Payment Type is required';
    }
    if (!values.transactionDate) {
      errors.transactionDate = 'Transaction Date is required';
    }
    if (!values.createdBy) {
      errors.createdBy = 'Created By is required';
    } else if (values.createdBy === 'other' && !values.otherCreatedBy) {
      errors.otherCreatedBy = 'Other Created By is required';
    }
    return errors;
  };

  const onSubmit = async (values, { setSubmitting, resetForm }) => {
    const payload = {
      ...values,
      createdBy: values.createdBy === 'other' ? values.otherCreatedBy : values.createdBy
    };
    delete payload.otherCreatedBy;

    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/addTransaction', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        toast({
          title: "Transaction added successfully!",
        })
        resetForm();
        setIsOtherSelected(false);
      } else {
        toast({
          title: "Error adding transaction",
        })
      }
    } catch (error) {
      console.error('Error adding transaction:', error);
      alert('Error adding transaction');
    }
    setSubmitting(false);
  };

  return (
    <div className="max-w-2xl min-w-full md:min-w-[26rem] lg:min-w-[30rem] xl:min-w-[34rem] 2xl:min-w-[40rem] mx-auto mt-10 p-6 bg-white rounded-lg">
      <h1 className="text-2xl font-bold mb-6 text-center">Add Transaction</h1>
      <Formik
        initialValues={initialValues}
        validate={validate}
        onSubmit={onSubmit}
      >
        {({ values, isSubmitting, setFieldValue }) => (
          <Form className="space-y-4">
            <div>
              <label htmlFor="transactionId" className="block text-sm font-medium text-gray-700">Transaction ID</label>
              <Field type="text" name="transactionId" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <ErrorMessage name="transactionId" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <label htmlFor="vendorId" className="block text-sm font-medium text-gray-700">Vendor ID</label>
              <Field type="text" name="vendorId" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <ErrorMessage name="vendorId" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount</label>
              <Field type="number" name="amount" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <ErrorMessage name="amount" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <label htmlFor="paymentType" className="block text-sm font-medium text-gray-700">Payment Type</label>
              <Field type="text" name="paymentType" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <ErrorMessage name="paymentType" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <label htmlFor="transactionDate" className="block text-sm font-medium text-gray-700">Transaction Date</label>
              <Field type="date" name="transactionDate" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
              <ErrorMessage name="transactionDate" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            <div>
              <label htmlFor="createdBy" className="block text-sm font-medium text-gray-700">Created By</label>
              <Field as="select" name="createdBy" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" onChange={e => {
                const value = e.target.value;
                setFieldValue('createdBy', value);
                setIsOtherSelected(value === 'other');
              }}>
                <option value="JAMES">JAMES</option>
                <option value="other">Other</option>
              </Field>
              <ErrorMessage name="createdBy" component="div" className="text-red-500 text-sm mt-1" />
            </div>
            {isOtherSelected && (
              <div>
                <label htmlFor="otherCreatedBy" className="block text-sm font-medium text-gray-700">Other Created By</label>
                <Field type="text" name="otherCreatedBy" className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" />
                <ErrorMessage name="otherCreatedBy" component="div" className="text-red-500 text-sm mt-1" />
              </div>
            )}
            <div>
              <button type="submit" disabled={isSubmitting} className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-black focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                Submit
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default AddTransactionForm;
