import React from 'react'
import { TableTransactions } from '../../../components/dashboard/Table'

const Transactions = () => {
  return (
    <div className='sm:px-8'>
      <TableTransactions />
    </div>
  )
}

export default Transactions
