import React, { useEffect, useState, useContext } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { AuthContext } from '../../../context/AuthContext';
import { useToast } from '../../../components/toast/use-toast.tsx';

const VendorsOrder = () => {
  const [data, setData] = useState([]);
  const { user } = useContext(AuthContext);
  const { toast } = useToast();

  const fetchVendors = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getallmodel`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(data);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setData(items);
  };

  const handleSubmit = async () => {
    const reorderedData = {};
    data.forEach((vendor, index) => {
      reorderedData[vendor.categoryName] = index.toString();
    });

    try {
      const response = await fetch('https://evmainbackend.theevshowroom.com/api/updateCategoryPriority', {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
          token: `${user?.token}`,
        },
        body: JSON.stringify(reorderedData),
      });

      const responseData = await response.json();
      if (responseData && responseData.success) {
        console.log('Priority updated successfully');
        toast({
          title: "Priority updated successfully",
        })
        fetchVendors();
      } else {
        console.log('Failed to update priority');
        toast({
          title: "Failed to update priority",
        })
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    fetchVendors();
  }, []);

  return (
    <div className='sm:px-8 py-8'>
      <h1 className='text-2xl font-bold mb-4'>Reorder Vendors</h1>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="vendors">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef} className='space-y-2'>
              {data && data.length > 0 && data.map((vendor, index) => (
                <Draggable key={vendor.categoryName} draggableId={vendor.categoryName} index={index}>
                  {(provided, snapshot) => (
                    <div 
                      className={`flex justify-between items-center p-4 border rounded-md ${snapshot.isDragging ? 'bg-blue-100' : 'bg-white'}`} 
                      ref={provided.innerRef} 
                      {...provided.draggableProps} 
                      {...provided.dragHandleProps}
                    >
                      <p className='text-lg font-medium'>{vendor.categoryName}</p>
                      <p className='text-sm text-gray-500'>Priority: {vendor.priority}</p>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>
      <button 
        onClick={handleSubmit} 
        className='mt-6 p-3 bg-blue-600 text-white rounded-md shadow-md hover:bg-blue-700 transition duration-300'
      >
        Submit Order
      </button>
    </div>
  );
}

export default VendorsOrder;
