import React from 'react'

const SingleSingleModelChargingInfo = ({
  modelChargingInfo
}) => {
  return (
    <div className='flex flex-col p-2'>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold"> Battery Capacity: </p>
        <p>{modelChargingInfo?.batteryCapacity}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">On Board Charger: </p>
        <p>{modelChargingInfo?.onBoardCharger}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">Charging Point: </p>
        <p>{modelChargingInfo?.chargingPoint}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">Price KWH: </p>
        <p>{modelChargingInfo?.priceKWH}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">Time: </p>
        <p>{modelChargingInfo?.time}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">Estimated Cost: </p>
        <p>{modelChargingInfo?.estimatedcost}</p>
      </div>
    </div>
  )
}

export default SingleSingleModelChargingInfo
