import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from '../../context/AuthContext'
import { format } from 'date-fns';
import Dropdown from "../dropdown/index"
import { EllipsisVerticalIcon } from "@heroicons/react/24/solid"
import ChangeStatusListbox from "./ChangeStatusListbox";
import { Menu } from '@headlessui/react';

export const Table = () => {
  const data = [
    {
      id: 1,
      order: "#95954",
      status: "Active",
      name: "Jone Doe",
      email: "jonne62@gmail.com",
      date: "2023-06-28",
      time: "10:00 AM",
    },
    {
      id: 2,
      order: "#95954",
      status: "Cancelled",
      name: "Jone Doe",
      email: "jonne62@gmail.com",
      date: "2023-06-29",
      time: "2:30 PM",
    },
    {
      id: 3,
      order: "#95954",
      status: "Complete",
      name: "Jone Doe",
      email: "jonne62@gmail.com",
      date: "2023-06-30",
      time: "9:15 AM",
    },
    {
      id: 4,
      order: "#95954",
      status: "Active",
      name: "Mary Poppins",
      email: "marypoppins@gmail.com",
      date: "2023-06-30",
      time: "3:45 PM",
    },
  ];

  const tableContainerStyles = {
    maxHeight: "400px", // Set an appropriate height for the container
    overflow: "auto",
  };

  const getStatusTextColor = (status) => {
    switch (status) {
      case "Active":
        return "text-green-500";
      case "Cancelled":
        return "text-red-500";
      case "Complete":
        return "text-orange-500";
      default:
        return "";
    }
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <div className="flex justify-between items-center p-2">
              <h2 className="text-xl ">Total money spent</h2>
              <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
                View Booking
              </button>
            </div>
            <div className="min-w-full">
              <div style={tableContainerStyles} className="table-container">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        ORDER
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        STATUS
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        DATE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        CUSTOMER
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {item.order}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap ${getStatusTextColor(
                            item.status
                          )}`}
                        >
                          {item.status}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {item.date}
                        </td>
                        <td className="px-6 py-4 text-sm text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {item.name}
                          </a>
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {item.time}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Table2 = () => {
  const { user } = useContext(AuthContext);
  const [bookingData, setBookingData] = useState([]);

  // const fetchBookingData = async () => {
  //   const url = 'https://evmainbackend.theevshowroom.com/api/bookinglistbyvendorid';
  //   try{
  //     const response = await fetch(url, {
  //       method: "GET",
  //       headers: {
  //         Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
  //         'Content-Type': "application/json",
  //         Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
  //         // token: 'uoWtB46GBnIwU7vLGfVJpPnJDHFSxb0X'
  //         token: `${user?.token}`
  //       }
  //     });

  //     const responseData = await response.json();

  //     if(responseData && responseData.status){
  //       setBookingData(responseData.data);
  //     }
  //   } catch(e) {
  //     console.log(e);
  //   }
  // }

  // useEffect(() => {
  //   fetchBookingData();
  // }, [user]);

  return (
    <>
      <div className="">
        <div className="flex justify-between  p-2">
          <h2 className="text-xl">Test Drive by models</h2>
          <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
            View More
          </button>
        </div>
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-scroll border rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Booking ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Model ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Model
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Status
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    User ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Name
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Email ID
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Address
                  </th>
                  {/* <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    State
                  </th> */}
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Zip Code
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Post
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Booking Time
                  </th>
                  <th
                    scope="col"
                    className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase "
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200">
                  {
                    bookingData.map((data)=>{
                      return(
                        <tr>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.bookingId}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.modelId}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.model}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.bookingStatus}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.userid}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {`${data?.firstName} ${data?.lastName}`}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.emailId}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.address}
                          </td>
                          {/* <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.state}
                          </td> */}
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.zipcode}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.post}
                          </td>
                          <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                            {data?.created}
                          </td>
                          <td>
                          <Dropdown
                            button={
                              <EllipsisVerticalIcon className="h-6 w-6 text-black"/>
                            } 
                            children={
                              <div className="flex p-4 w-56 flex-col justify-start rounded-md bg-white text-black bg-cover bg-no-repeat border shadow-xl shadow-shadow-500 dark:shadow-none">
                                <p className="mb-2">Change Status</p>
                                <ChangeStatusListbox status={data?.bookingStatus} bookingId={data?.bookingId} token={user?.token}/>
                              </div>
                            }
                          classNames={"py-2 top-4 -left-[135px] w-max"}
                          buttonClassName="ml-auto"
                        />
                          </td>
                        </tr>
                      )
                    })
                  }
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </>
  );
};

export const Table3 = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const fetchProductClickData = async () => {
    const url = 'https://evmainbackend.theevshowroom.com/api/vendorclickedproductlist';
    try{
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if(responseData && responseData.status){
        setData(responseData.data);
      }
      else{
        setData([]);
      }
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
   fetchProductClickData();
  }, [user]);

  const tableContainerStyles = {
    maxHeight: "400px", // Set an appropriate height for the container
    overflow: "auto",
  };

  const getDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  }

  const getTime = (date) => {
    return format(new Date(date), 'h:mm:ss a');
  }

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <div className="flex justify-between items-center p-2">
              <h2 className="text-xl ">Product Click Details</h2>
              <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
                View Booking
              </button>
            </div>
            <div className="min-w-full">
              <div style={tableContainerStyles} className="table-container">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        ID
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        NAME
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        DATE
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-right text-gray-500 uppercase"
                      >
                        TIME
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((item) => (
                      <tr key={item.id}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {item.modelid}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap`}
                        >
                          {item.model}
                        </td>
                        <td className="px-6 py-4 text-sm text-gray-800 whitespace-nowrap">
                          {getDate(item.created)}
                        </td>
                        <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                          <a className="text-gray-800" href="#">
                            {getTime(item.created)}
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const Table4 = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);

  const fetchNewsletterData = async () => {
    const url = 'https://evmainbackend.theevshowroom.com/api/usersubscribelist';
    try{
      const response = await fetch(url, {
        method: "GET",
        headers: {
          Authorization: "Basic ZUF1dG9Vc2VyOmF1dG9AMTIz",
          'Content-Type': "application/json",
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
          token: `${user?.token}`
        }
      });

      const responseData = await response.json();

      if(responseData && responseData.status){
        setData(responseData.data);
      }
      else{
        setData([]);
      }
    } catch(e) {
      console.log(e);
    }
  }

  useEffect(() => {
    fetchNewsletterData();
  }, [user]);

  const tableContainerStyles = {
    maxHeight: "400px", // Set an appropriate height for the container
    overflow: "auto",
  };

  return (
    <div className="flex flex-col">
      <div className="overflow-x-auto">
        <div className="p-1.5 w-full inline-block align-middle">
          <div className="overflow-hidden border rounded-lg">
            <div className="flex justify-between items-center p-2">
              <h2 className="text-xl ">Newsletter Subscribers</h2>
              {/* <button className="px-3 py-1 text-black rounded-lg text-sm font-normal border border-gray-500">
                View Newsletter Subscriptions
              </button> */}
            </div>
            <div className="min-w-full">
              <div style={tableContainerStyles} className="table-container">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-gray-50">
                    <tr>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        Index
                      </th>
                      <th
                        scope="col"
                        className="px-6 py-3 text-xs font-bold text-left text-gray-500 uppercase"
                      >
                        Email ID
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-200">
                    {data.map((item, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 text-sm font-medium text-gray-800 whitespace-nowrap">
                          {index + 1}
                        </td>
                        <td
                          className={`px-6 py-4 text-sm text-gray-800 whitespace-nowrap`}
                        >
                          {item.emailId}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const TableTransactions = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');

  const fetchVendorTransactions = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getTransactions`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
          // token: `ej07HSJirUUXn1j1CW4UdblFYp8w0uaV`,
          token: `${user?.token}`
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (user) {
      fetchVendorTransactions();
    }
  }, [user]);

  const formatDate = (date) => {
    return format(new Date(date), 'MMMM do yyyy');
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setStartDate('');
    setEndDate('');
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setSelectedMonth('');
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setSelectedMonth('');
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
  };

  const filteredData = data.filter((item) => {
    if (!item.transaction_id || item.payment_type === 'click') {
      return false;
    }
    if (selectedVendor && item.vendor_name !== selectedVendor) {
      return false;
    }
    if (selectedMonth) {
      return new Date(item.transaction_date).getMonth() === new Date(`${selectedMonth} 1, 2024`).getMonth();
    } else if (startDate && endDate) {
      const transactionDate = new Date(item.transaction_date);
      return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
    } else {
      return true;
    }
  });

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const vendors = [...new Set(data.map((item) => item.vendor_name))]; // Get unique vendor names

  return (
    <div className="container mx-auto px-4">
      <div className="flex justify-between items-center py-4">
        <h2 className="text-2xl font-bold">Transaction Details</h2>
        <div className="flex items-center space-x-4">
          <Menu as="div" className="relative">
            <Menu.Button className="px-4 py-2 border border-gray-300 text-black rounded hover:bg-gray-200">
              {selectedMonth || 'Filter by Month'}
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleMonthChange('')}
                    className={`${
                      active ? 'bg-gray-100' : ''
                    } w-full text-left px-4 py-2`}
                  >
                    <em>All</em>
                  </button>
                )}
              </Menu.Item>
              {months.map((month) => (
                <Menu.Item key={month}>
                  {({ active }) => (
                    <button
                      onClick={() => handleMonthChange(month)}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } w-full text-left px-4 py-2`}
                    >
                      {month}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          <div>
            <label htmlFor="vendor" className="sr-only">
              Filter by Vendor
            </label>
            <select
              id="vendor"
              value={selectedVendor}
              onChange={handleVendorChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="">All Vendors</option>
              {vendors.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
            </select>
          </div>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            placeholder="End Date"
          />
        </div>
      </div>
      <div className="overflow-auto">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Transaction ID
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Amount
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Payment Type
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Transaction Date
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Created By
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Vendor Name
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{item.id}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{item.transaction_id}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{item.amount}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm capitalize">{item.payment_type}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{formatDate(item.transaction_date)}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{item.created_by}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm capitalize">{item.vendor_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export const ClickTransactionsTable = () => {
  const { user } = useContext(AuthContext);
  const [data, setData] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');

  const fetchVendorTransactions = async () => {
    const url = `https://evmainbackend.theevshowroom.com/api/getClickTransactions`;
    try {
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: 'connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E',
          token: `${user?.token}`,
        },
      });

      const responseData = await response.json();

      if (responseData && responseData.status) {
        setData(responseData.data);
      } else {
        setData([]);
      }
    } catch (e) {
      console.log(e);
    }
  };

  useEffect(() => {
    if (user) {
      fetchVendorTransactions();
    }
  }, [user]);

  const formatDate = (date) => {
    if(!date) return ''
    return format(new Date(date), 'MMMM do yyyy');
  };

  const handleMonthChange = (month) => {
    setSelectedMonth(month);
    setStartDate('');
    setEndDate('');
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
    setSelectedMonth('');
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
    setSelectedMonth('');
  };

  const handleVendorChange = (e) => {
    setSelectedVendor(e.target.value);
  };

  const filteredData = data.filter((item) => {
    if (selectedVendor && item.vendor_name !== selectedVendor) {
      return false;
    }
    if (selectedMonth) {
      return new Date(item.created).getMonth() === new Date(`${selectedMonth} 1, 2024`).getMonth();
    } else if (startDate && endDate) {
      const transactionDate = new Date(item.created);
      return transactionDate >= new Date(startDate) && transactionDate <= new Date(endDate);
    } else {
      return true;
    }
  });

  const totalClicks = filteredData.length;
  const totalMoneySpent = filteredData.reduce((total, item) => total + parseFloat(item.amount), 0);

  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  const vendors = [...new Set(data.map((item) => item.vendor_name))]; // Get unique vendor names

  return (
    <div className="container mx-auto px-4 flex flex-col min-h-screen">
      <div className="flex justify-between items-center py-4">
        <h2 className="text-2xl font-bold">Click Transaction Details</h2>
        <div className="flex items-center space-x-4">
          <Menu as="div" className="relative">
            <Menu.Button className="px-4 py-2 border border-gray-300 text-black rounded hover:bg-gray-200">
              {selectedMonth || 'Filter by Month'}
            </Menu.Button>
            <Menu.Items className="absolute right-0 mt-2 w-48 bg-white border border-gray-300 rounded shadow-lg">
              <Menu.Item>
                {({ active }) => (
                  <button
                    onClick={() => handleMonthChange('')}
                    className={`${
                      active ? 'bg-gray-100' : ''
                    } w-full text-left px-4 py-2`}
                  >
                    <em>All</em>
                  </button>
                )}
              </Menu.Item>
              {months.map((month) => (
                <Menu.Item key={month}>
                  {({ active }) => (
                    <button
                      onClick={() => handleMonthChange(month)}
                      className={`${
                        active ? 'bg-gray-100' : ''
                      } w-full text-left px-4 py-2`}
                    >
                      {month}
                    </button>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Menu>
          <div>
            <label htmlFor="vendor" className="sr-only">
              Filter by Vendor
            </label>
            <select
              id="vendor"
              value={selectedVendor}
              onChange={handleVendorChange}
              className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            >
              <option value="">All Vendors</option>
              {vendors.map((vendor) => (
                <option key={vendor} value={vendor}>
                  {vendor}
                </option>
              ))}
            </select>
          </div>
          <input
            type="date"
            value={startDate}
            onChange={handleStartDateChange}
            className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            placeholder="Start Date"
          />
          <input
            type="date"
            value={endDate}
            onChange={handleEndDateChange}
            className="px-4 py-2 border border-gray-300 rounded focus:outline-none focus:ring focus:border-blue-300"
            placeholder="End Date"
          />
        </div>
      </div>
      <div className="overflow-auto flex-grow border">
        <table className="min-w-full bg-white border">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Amount (€)
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Transaction Date
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Vendor Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-300 bg-gray-100 text-left text-xs font-semibold text-gray-600 uppercase tracking-wider">
                Model Name
              </th>
            </tr>
          </thead>
          <tbody>
            {filteredData.map((item) => (
              <tr key={item.id}>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{item.id}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">€ {item.amount}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm">{formatDate(item.created)}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm capitalize">{item.vendor_name}</td>
                <td className="px-6 py-4 border-b border-gray-200 text-sm capitalize">{item.model_name}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="bg-white p-4 shadow-md border-t border-gray-300 flex justify-between items-center sticky bottom-2">
        <span className="font-bold">Total Clicks: {totalClicks}</span>
        <span className="font-bold">Total Money Spent: € {totalMoneySpent.toFixed(2)}</span>
      </div>
    </div>
  );
};
