import React from 'react'
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../../../components/tooltip/index.tsx"

const ProductLink = ({text}) => {
  return (
    <TooltipProvider>
      <Tooltip>
        <TooltipTrigger className='max-w-[80%] lg:max-w-[85%]'><p className='whitespace-nowrap overflow-hidden text-ellipsis'>{text}</p></TooltipTrigger>
        <TooltipContent>
          <p>{text}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

export default ProductLink
