import React from 'react'

const SingleModelWarranty = ({
  modelWarranty
}) => {
  return (
    <div className='flex flex-col p-2'>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold"> Type: </p>
        <p>{modelWarranty?.type}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold"> Value: </p>
        <p>{modelWarranty?.value}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold"> Description: </p>
        <p>{modelWarranty?.description}</p>
      </div>
    </div>
  )
}

export default SingleModelWarranty
