/* eslint-disable */

import { HiX } from "react-icons/hi";
import Links from "./components/Links";
import routes from "../../routes";
// import evLogo from "../../assets/img/ev-logo-neww.png"

const Sidebar = ({ open, onClose }) => {
  return (
    <div
      className={`sm:none duration-175 linear fixed !z-50 flex min-h-full flex-col bg-gray-100 pb-10 shadow-2xl shadow-white/5 transition-all ${
        open ? "translate-x-0" : "-translate-x-96"
      }`}
    >
      <span
        className="absolute top-4 right-4 block cursor-pointer xl:hidden"
        onClick={onClose}
      >
        <HiX />
      </span>

      <div className={`mx-8 mt-[10px] h-20 flex items-center`}>
        {/* <img className="h-20 p-2" src={evLogo} alt="logo"/> */}
        <p className="text-4xl">Admin</p>
      </div>
      <div className="mt-[px] mb-7 h-px bg-gray-300 dark:bg-white/30" />

      <ul className="mb-auto pt-1 max-h-[80vh] overflow-auto">
        <Links routes={routes} />
      </ul>

   
    </div>
  );
};

export default Sidebar;
