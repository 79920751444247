import React, { useEffect, useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import { Toaster } from "./components/toast/toaster.tsx"

import Login from "./layouts/account/Login";
import Admin from "./layouts/admin";
import Register from "./layouts/account/Register";
import ForgotPassword from "./layouts/account/ForgotPassword";
import ResetPassword from "./layouts/account/ResetPassword";
import { AuthContext } from "./context/AuthContext";

const App = () => {
  const [user, setUser] = useState(null);

  return (
    <AuthContext.Provider value={{ user, setUser }}>
      <Routes>
        <Route path="admin/*" element={<Admin />} />
        <Route path="/login" element={<Login />} />
        {/* <Route path="/register" element={<Register />} /> */}
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/" element={<Navigate to="/login" replace />} />
      </Routes>
      <Toaster />
    </AuthContext.Provider>
  );
};

export default App;
