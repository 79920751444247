import React from 'react'

const SingleModelColor = ({
  modelColor
}) => {
  return (
    <div className='flex flex-col p-2'>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">Color Code: </p>
        <p>{modelColor?.colorCode}</p>
      </div>
      <div className='flex justify-between gap-4'>
        <p className="font-semibold">Color Name: </p>
        <p>{modelColor?.colorName}</p>
      </div>
    </div>
  )
}

export default SingleModelColor
