import React from 'react'
import { Check, ChevronsUpDown } from "lucide-react"

import { cn } from "../../../../lib/utils"
import { Button } from "../../../../components/button/index.tsx"
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "../../../../components/command/index.tsx"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../../../components/popover/index.tsx"
import { bodyTypesData } from '../../../../constant/data';

const ModelType = ({
  selectedModelType,
  handleModelTypeChange
}) => {
  const [modelOpen, setModelOpen] = React.useState(false)

  return (
    <Popover open={modelOpen} onOpenChange={setModelOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={modelOpen}
            className={cn("w-full lg:w-8/12 justify-between capitalize")}
          >
            {selectedModelType
              ? bodyTypesData.find((modelTyoe) => modelTyoe.name === selectedModelType.name)?.name
              : "Select Model Type."}
            <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className={cn("w-[200px] p-0")}>
          <Command>
            {/* <CommandInput placeholder="Search Model" type='password' name='devicePassword' autoComplete='new-password' /> */}
            <CommandEmpty>Loading.</CommandEmpty>
            <CommandGroup>
              {bodyTypesData.map((modelType) => (
                <CommandItem
                  key={modelType.id}
                  value={modelType.id}
                  onSelect={() => {
                    handleModelTypeChange(modelType)
                    setModelOpen(false)
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selectedModelType?.id === modelType.id ? "opacity-100" : "opacity-0"
                    )}
                  />
                  {modelType.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
  )
}

export default ModelType
