import React, {useState, useEffect} from 'react'
import logoCar from "../../assets/img/ev-logo-neww.png"
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Formik } from 'formik';
import {
  EyeIcon,
  EyeSlashIcon
} from "@heroicons/react/24/outline";

const ResetPassword = () => {
  const [isVerified, setIsVerified] = useState(false);
  const [responseErrors, setResponseErrors] = useState(false);
  const navigate = useNavigate();
  const location = useLocation()
  const queryParameters = new URLSearchParams(location.search)
  const token = queryParameters.get("token");
  const [showPass, setShowPass] = useState(false);

  const handleTokenVerification = async () => {
    const url = "https://evmainbackend.theevshowroom.com/api/verify-token?token="+token;
    try{
      const response = await fetch(url, {
        method: 'GET',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
        }
      });
      const dataResponse = await response.json();
      if(dataResponse.data){
        setIsVerified(true)
        setResponseErrors(null);
      }else{
        setResponseErrors('Link Expired');
      }
    } catch(e){
      console.log('Error while Sending Forgot Password Mail: ',e);
    }
  }

  const handleSubmit = async (values, {setSubmitting}) => {
    const url = "https://evmainbackend.theevshowroom.com/api/resetPassword";
    try{
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          Authorization: 'Basic ZUF1dG9Vc2VyOmF1dG9AMTIz',
          'Content-Type': 'application/json',
          Cookie: "connect.sid=s%3ATyKx5x0PRHWMRZdVkFdGPRU7JpfmQP6t.eisRGbP%2BWjSxCQCpeywoGPk2WnaYbQ6j5xvTHYFPS2E",
        },
        body: JSON.stringify({
          password: values.password,
          token,
          requestFor: "user"
        })
      });
      const dataResponse = await response.json();
      if(dataResponse.status){
        setResponseErrors(null);
        navigate('/');
      }else{
        setResponseErrors(dataResponse.message);
      }
      setSubmitting(false);
    } catch(e){
      console.log('Error while Sending Forgot Password Mail: ',e);
    }
  }

  const validateForm = (values) => {
    setResponseErrors(null);
    const errors = {};

    if(!values.password){
      errors.password = 'Password is required'
    } else if( values.password.length < 8){
      errors.password = 'Password should be of minimum 8 characters length'
    }
    return errors;
  }

  useEffect(()=>{
    handleTokenVerification();
  },[]);

  return (
    <section className="flex justify-center">
      <div className="h-screen">
        <div className="items-center justify-center bg-white">
          <div className="w-full">
            <div className="block rounded-lg">
              <div className="lg:flex lg:flex-wrap">

                <div className="lg:flex items-center  bg-[#eee] h-screen lg:w-6/12 hidden">
                  <div className="px-4 py-6 text-white md:mx-6 md:p-12">
                    <h4 className="mb-6 text-2xl font-bold text-black">
                      Delivering 100'000s of NEW qualified leads to Electric Car Manufactures every month
                    </h4>
                    <p className="text-xl text-gray-700">
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et
                      dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex
                      ea commodo consequat.
                    </p>
                  </div>
                </div>

                <div className="px-4 md:px-0 lg:w-6/12 ">
                  <div className="md:mx-6 md:p-12 ">
                    <div className="text-center mb-8">
                      <h4 className=" mt-24 pb-1 sm:text-2xl text-xl font-semibold">
                        Welcome Back!
                      </h4>
                      <p className="mb-4">Please Enter your new password</p>
                    </div>

                    <Formik
                      initialValues={{ password: '' }}
                      validate={validateForm}
                      onSubmit={(values, { setSubmitting }) => handleSubmit(values, { setSubmitting })}
                    >
                      {({ 
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting, 
                      }) => (
                        <form
                          onSubmit={handleSubmit}
                        >
                          <div className="">
                            <div className="mb-10">
                              <div className="flex items-center relative">
                                <input
                                  type={showPass ? 'text' : 'password'}
                                  name="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  placeholder="Password"
                                  className="block w-full rounded outline-none px-3.5 py-3 text-gray-900 shadow-[0px_2px_4px_0px_rgba(0,0,0,0.1)]"
                                />
                                <button type="button" className="absolute right-0 ring-0" onClick={() => setShowPass(prev => !prev)}>
                                  {showPass ? <EyeSlashIcon className="ring-0 w-6 h-6 mx-2"/> :<EyeIcon className="ring-0 w-6 h-6 mx-2" />}
                                </button>
                              </div>
                              <p className="text-orange-600 ml-2">{errors.password && touched.password && errors.password}</p>
                            </div>
                          </div>
                          <p className="text-orange-600 text-center mt-2">{responseErrors}</p>

                          <div className="mb-12 pb-1 pt-1 text-center">
                            <button
                              type="submit"
                              disabled={isSubmitting || !isVerified}
                              className="block w-full rounded-md bg-black px-3.5 py-3 text-center text-sm font-semibold text-white shadow-sm hover:bg-black-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                              Reset Password
                            </button>
                          </div>
                        </form>
                      )}
                    </Formik>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>
        <img
          src={logoCar}
          alt="Logo"
          className="absolute top-0 left-6 mt-4 mr-4 w-24 h-16"
        />
      </div>
    </section>
  );
}

export default ResetPassword
